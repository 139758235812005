// flex-box allineamenti
$d-flex: flex;
$d-block: block;
$d-inline-block: inline-block;
$flex-space-between: space-between;
$flex-space-around: space-around;
$flex-content-center: center;
$flex-stretch: stretch;
$flex-start: flex-start;
$flex-end: flex-end;
$flex-baseline: baseline;
$flex-wrap: wrap;
$flex-direction-row: row;
$flex-direction-column: column;

// Transitions
$trans200: all 200ms linear;
$trans500: all 500ms linear;
$trans500e: all 500ms ease-in;
$trans500eo: all 500ms ease-out;
$trans1000e: all 1000ms ease-in;
$trans1000eo: all 1000ms ease-out;
$trans1500e: all 1500ms ease-in;
$trans1500eo: all 1500ms ease-out;
$trans2000e: all 2000ms ease-in;
$trans2000eo: all 2000ms ease-out;

$cubic: transform 1s cubic-bezier(0.9,0,.2,1);

// scss-docs-start position-map
$position-type: (
  'static': static,
  'relative': relative,
  'absolute': absolute,
  'fixed': fixed,
  'sticky': sticky
) !default;

@each $name, $value in $grid-breakpoints {
    @media (min-width: $value) {
        @each $k, $v in $position-type {
            .position-#{$name}-#{$k} {
                position: $v !important;
            }
        }
    }
}

.w-max-content {
    width: max-content;
}


@each $name, $value in $grid-breakpoints {
    @media (min-width: $value) {
        .w-#{$name}-max-content {
            width: max-content !important;
        }
    }
}

$column-items: (
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
) !default;

@each $name, $value in $grid-breakpoints {
    @media (min-width: $value) {
        @each $k, $v in $column-items {
            .padding-start-#{$name}-#{$k} {
                padding-left: calc(100% / 12 * #{$v}) !important;
            }
            .padding-end-#{$name}-#{$k} {
                padding-right: calc(100% / 12 * #{$v}) !important;
            }
        }
    }
}


@each $name, $value in $grid-breakpoints {
    @media (min-width: $value) {
        @each $k, $v in $position-values {
            .w-#{$name}-#{$k} {
                width: $v !important;
            }
        }
    }
}

@each $name, $value in $grid-breakpoints {
    @media (min-width: $value) {
        @each $k, $v in $position-values {
            .top-#{$name}-#{$k} {
                top: $v !important;
            }
            .bottom-#{$name}-#{$k} {
                bottom: $v !important;
            }
            html:not(.rtl) {
                .start-#{$name}-#{$k} {
                    left: $v !important;
                }
                .end-#{$name}-#{$k} {
                    right: $v !important;
                }
            }
            html.rtl {
                .start-#{$name}-#{$k} {
                    right: $v !important;
                }
                .end-#{$name}-#{$k} {
                    left: $v !important;
                }
            }
        }
    }
}

// scss-docs-start position-map
html:not(.rtl) {
    $translate-type: (
    'none': translate(0%, 0%),
    'middle': translate(-50%, -50%),
    'middle-x': translateX(-50%),
    'middler-x': translateX(50%),
    'middle35-y': translateY(-35%),
    'middle-y': translateY(-50%),
    'middler-y': translateY(50%),
    'full': translate(-100%, -100%),
    'full-x': translateX(-100%),
    'fullr-x': translateX(100%),
    'full-y': translateY(-100%),
    'middle-y-middler-x': translate(50%, -50%),
    'fullr-y-middle-x': translate(-50%, 100%),
    ) !default;

    @each $k, $v in $translate-type {
        .translate-#{$k} {
            transform: $v !important;
        }
    }

    @each $name, $value in $grid-breakpoints {
        @media (min-width: $value) {
            @each $k, $v in $translate-type {
                .translate-#{$name}-#{$k} {
                    transform: $v !important;
                }
            }
        }
    }
}

html.rtl {
    $translate-type: (
    'none': translate(0%, 0%),
    'middle': translate(50%, -50%),
    'middle-x': translateX(50%),
    'middler-x': translateX(-50%),
    'middle35-y': translateY(-35%),
    'middle-y': translateY(-50%),
    'middler-y': translateY(50%),
    'full': translate(100%, -100%),
    'full-x': translateX(100%),
    'fullr-x': translateX(-100%),
    'full-y': translateY(-100%),
    'middle-y-middler-x': translate(-50%, -50%),
    'fullr-y-middle-x': translate(50%, 100%),
    ) !default;

    @each $k, $v in $translate-type {
        .translate-#{$k} {
            transform: $v !important;
        }
    }

    @each $name, $value in $grid-breakpoints {
        @media (min-width: $value) {
            @each $k, $v in $translate-type {
                .translate-#{$name}-#{$k} {
                    transform: $v !important;
                }
            }
        }
    }
}
