.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}

#preload {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $bianco;
}

#preload i {
    display: block;
    width: 90px;
    height: 90px;
    position: absolute;
    top: calc(50% - 45px);
    left: calc(50% - 45px);
    color: $blu_cliente;
    font-size: $font-size-90;
}

.lds-default {
    display: inline-block;
    display: block;
    width: 90px;
    height: 90px;
    position: absolute;
    top: calc(50% - 45px);
    left: calc(50% - 45px);
}
.lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: $blu_cliente;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}
.lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}
.lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}
.lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}
.lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}
.lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}
.lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}
.lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}
.lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}
.lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}
.lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}
.lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}
@keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}

.w-maxcontent {
    width: max-content;
}

.logoPreload {
    position: absolute;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, calc(-100% - 90px));
}

.sameheight {
    display: $d-block;
}

/* ANIMAZIONE LOADING ELLISSE */
.loader-ellips {
    font-size: 20px; /* change size here */
    position: relative;
    width: 4em;
    height: 1em;
    margin: 30px auto;
}

.loader-ellips__dot {
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    background: #555; /* change color here */
    position: absolute;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}

.loader-ellips__dot:nth-child(1),
.loader-ellips__dot:nth-child(2) {
    left: 0;
}
.loader-ellips__dot:nth-child(3) {
    left: 1.5em;
}
.loader-ellips__dot:nth-child(4) {
    left: 3em;
}

@keyframes reveal {
    from {
        transform: scale(0.001);
    }
    to {
        transform: scale(1);
    }
}

@keyframes slide {
    to {
        transform: translateX(1.5em);
    }
}

.weightGrowtoBold:hover {
    text-shadow: -1px -1px 0 $verde_cliente, 1px -1px 0 $verde_cliente,
        -1px 1px 0 $verde_cliente, 1px 1px 0 $verde_cliente;
}

.loader-ellips__dot:nth-child(1) {
    animation-name: reveal;
}

.loader-ellips__dot:nth-child(2),
.loader-ellips__dot:nth-child(3) {
    animation-name: slide;
}

.loader-ellips__dot:nth-child(4) {
    animation-name: reveal;
    animation-direction: reverse;
}

// ANIMAZIONE LINK TOP NAV
.nav-header-top a,
.nav-search-top a {
    position: relative;
    color: $nero;
    text-decoration: none;
    transition: $trans200;
}
.nav-header-top a:hover,
.nav-search-top a:hover {
    color: $blu_cliente;
}
.nav-header-top span,
.nav-search-top span {
    color: $blu_cliente;
    position: relative;
    font-family: $font-bold;
}

// ANIMAZIONE LINK TOP NAV
.nav-header-top a {
    position: relative;
    transition: $trans200;
    font-family: $font-regular;
}

.form-rounded {
    border-radius: 1rem;
}

.titolo_header {
    font-size: $font-size-26;
    font-family: $font-bold;
    color: $arancione_cliente;
    display: $d-block;
}

.sottotitolo_header {
    font-size: $font-size-17;
    font-family: $font-bold;
    color: $verde_cliente;
    display: $d-block;
}

.titolo {
    font-family: $font-bold;
    color: $arancione_cliente;
    display: $d-block;

    @include media-breakpoint-up(xs) {
        font-size: $font-size-24;
    }
    @include media-breakpoint-up(sm) {
        font-size: $font-size-26;
    }
    @include media-breakpoint-up(md) {
        font-size: $font-size-30;
    }
}

.sottotitolo {
    font-family: $font-bold;
    color: $arancione_cliente;
    display: $d-block;

    @include media-breakpoint-up(xs) {
        font-size: $font-size-16;
    }
    @include media-breakpoint-up(sm) {
        font-size: $font-size-18;
    }
    @include media-breakpoint-up(md) {
        font-size: $font-size-20;
    }
}

.link_sito {
    color: $arancione_cliente;
    text-decoration: underline;
    transition: $trans200;
    display: $d-block;
}

.link_sito:hover {
    color: $black;
}

.cornice {
    height: 55px;
}

.cornice.orx {
    background-position: top left;
}

.cornice_doppia {
    height: 55px;
}

.fiw {
    display: $d-flex;
}

.fiw img {
    object-fit: cover;
    height: auto;
    width: 100%;
}

.bloccoBriciole {
    background-color: $grigio_azzurro_cliente;
}

.bloccoBriciole .cornice {
    background-size: cover;
}

.leftCol {
    display: $d-flex;
    flex-direction: $flex-direction-column;
    justify-content: $flex-start;
}

.menuProdottiLeft {
    padding: $p-3 0;
    background-color: $white;
}

.menuProdottiLeft ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.menuProdottiLeft li {
    padding: $p-2 0;
}

.menuProdottiLeft span {
    color: $verde_cliente;
}

.menuProdottiLeft a {
    position: relative;
    transition: $trans200;
    text-decoration: none;
    color: $arancione_cliente;
    padding-bottom: $p-1;
}

.menuProdottiLeft a::after {
    color: $verdechiaro_cliente;
    @include aniLink($verdechiaro_cliente, 3px);
}

.menuProdottiLeft a:hover {
    color: $verde_cliente;
}

.menuProdottiLeft a:hover::after {
    color: $verde_cliente;
    @include aniLinkHover($verde_cliente, 3px);
}

.menuProdottiLeft ul.subNav {
    margin: 0 $p-3 $p-2 $p-3;
}

.menuProdottiLeft ul.subNav a,
.menuProdottiLeft ul.subNav span {
    font-size: $font-size-14;
    color: $grigio_chiaro_cliente;
}

.menuProdottiLeft ul.subNav span {
    text-decoration: line-through;
}

.richiediInfo {
    background-color: $arancione_cliente;
    justify-content: $flex-content-center;
    align-items: $flex-content-center;
    transition: $trans200;
}

.richiediInfo a {
    text-decoration: none;
    font-family: $font-bold;
    color: $white;
    width: 100%;
    height: 100%;
    display: $d-flex;
    justify-content: $flex-content-center;
    align-items: $flex-content-center;
}

.richiediInfo:hover {
    background-color: darken($arancione_cliente, 20%);
}

.tornaIndietro {
    background-color: $verde_cliente;
    position: relative;
    display: $d-flex;
    justify-content: $flex-content-center;
    align-items: $flex-content-center;
    transition: $trans200;
}

.tornaIndietro a {
    color: $white;
    width: 100%;
    height: 100%;
    display: $d-flex;
    justify-content: $flex-content-center;
    align-items: $flex-content-center;
    text-decoration: none;
}

.tornaIndietro:hover {
    background-color: lighten($verde_cliente, 10%);
}

.bloccoBriciole .bricioleRow {
    height: 48px;
    position: relative;
    display: $d-flex;
    align-items: $flex-content-center;
}

.briciole {
    display: $d-flex;
    align-items: $flex-content-center;
    padding-left: $p-3;
    height: 24px;
    position: absolute;
    width: max-content;
}

.briciole a,
.briciole span {
    margin: 0 $p-1;
    transition: $trans200;
}

.briciole a:hover {
    color: $arancione_cliente;
}

.briciole span {
    font-family: $font-bold;
    color: $verde_cliente;
}

.bricioleRow {
    @include media-breakpoint-down(sm) {
        overflow-x: scroll;
    }
}

.c-pointer {
    cursor: pointer;
}

:root {
    --swiper-navigation-color: $verde_cliente;
}

.card-shadow {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-shadow:hover {
    -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.22);
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.22);
}

.zigrinato .injectable {
    transition: $trans200;
}
.zigrinato:hover .injectable {
    transform: scale(1.1);
}

.zigrinato g[id^="righe--inject-"] {
    transform: translateX(-100%);
    opacity: 0;
    transition: $trans200;
}
.zigrinato:hover g[id^="righe--inject-"] {
    transform: translateX(0%);
    opacity: 1;
}

.customcontent.swiper-slide {
    height: auto;
}
.customcontent.swiper-slide > .row {
    height: 100%;
}

.greenonhover2 {
    transition: $trans200;
}
.greenonhover2:hover {
    color: $verde_cliente !important;
}

.whiteonhover {
    transition: $trans200;
}
.whiteonhover:hover {
    color: $bianco !important;
}

.greenline_bottom > span,
.greenline_bottom > a,
.greenline_bottom > button,
.greenline_bottom > .btn-carrello-top {
    position: relative;
    padding-bottom: $p-1;
    transition: $trans200;
}
.greenline_bottom > span::after,
.greenline_bottom > a::after,
.greenline_bottom > button::after,
.greenline_bottom > .btn-carrello-top::after {
    @include aniLink($verde_cliente, 3px);
}
.greenline_bottom:hover > span::after,
.greenline_bottom:hover > a::after,
.greenline_bottom:hover > button::after {
    @include aniLinkHover($verde_cliente, 3px);
}
.greenline_bottom > .btn-carrello-top.btn-attivo::after {
    @include aniLinkHover($verde_cliente, 3px);
}
.greenline_bottom > button {
    outline: 0;
}

.whiteline_bottom > span,
.whiteline_bottom > a,
.whiteline_bottom > button,
.whiteline_bottom > .btn-carrello-top {
    position: relative;
    padding-bottom: $p-1;
    transition: $trans200;
}
.whiteline_bottom > span::after,
.whiteline_bottom > a::after,
.whiteline_bottom > button::after,
.whiteline_bottom > .btn-carrello-top::after {
    @include aniLink($bianco, 3px);
}
.whiteline_bottom:hover > span::after,
.whiteline_bottom:hover > a::after,
.whiteline_bottom:hover > button::after {
    @include aniLinkHover($bianco, 3px);
}
.whiteline_bottom > .btn-carrello-top.btn-attivo::after {
    @include aniLinkHover($bianco, 3px);
}
.whiteline_bottom > button {
    outline: 0;
}

.swalMaggiorenne .swal2-title {
    font-size: $font-size-18;
    flex-direction: column;
    color: $verde_cliente;
}
.swalMaggiorenne .swal2-actions {
    display: flex;
}
.swalMaggiorenne .swal2-actions > button {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-align: center;
    border: 1px solid black;
    font-family: $font-bold;
}
.swalMaggiorenne .swal2-actions > button.swal2-cancel {
    background-color: $verde_cliente;
    color: $bianco;
}
.swalMaggiorenne .swal2-actions > button.swal2-confirm {
    background-color: $giallo_cliente;
    color: $verde_cliente;
}

.bkg_grigio_chiaro3_cliente.opaquehover {
    transition: $trans200;
}
.bkg_grigio_chiaro3_cliente.opaquehover:hover {
    background-color: $grigio_chiaro3_cliente_opaco !important;
}

.bkg_giallo_cliente.opaquehover {
    transition: $trans200;
}
.bkg_giallo_cliente.opaquehover:hover {
    background-color: $giallo_cliente_opaco !important;
}
.slide-vertical-splitting,
.slide-horizontal-splitting {
    .char {
        overflow: hidden;
        color: transparent;

        &:before,
        &:after {
            visibility: visible;
            transition: $cubic;
            transition-delay: calc(0.4s + (0.04s * (var(--char-index))));
        }

        &:before {
            color: var(--inherited-color);
            transition-delay: calc(0.04s * (var(--char-index)));
        }
    }

    &.isvisible .char:before {
        transition-delay: calc(0.4s + (0.04s * (var(--char-index))));
    }
    &.isvisible .char:after {
        transition-delay: calc(0.04s * (var(--char-index)));
    }
}

.slide-vertical-splitting {
    .char:before {
        transform: translateY(-100%);
    }
    &.isvisible .char:before {
        transform: translateY(0%);
    }
    &.isvisible .char:after {
        transform: translateY(100%);
    }
}

.slide-horizontal-splitting {
    .char:before {
        transform: translateX(110%);
    }
    &.isvisible .char:before {
        transform: translateX(0%);
    }
    &.isvisible .char:after {
        transform: translateX(-110%);
    }
}

.btn_verdeacqua_cliente {
    background-color: $verdeacqua_cliente;
    color: $verde_cliente;
    transition: $trans200;
    border: 1px solid $verdeacqua_cliente;
}

.btn_verdeacqua_cliente:hover {
    background-color: $giallo_cliente;
    border: 1px solid $verde_cliente;
}

.social-footer {
    list-style: none;
}

.headerDecoration {
    top: calc(50% - 170px);
}

.headerCategory,
.headerRecipe {
    opacity: 0;
}
.headerCategory.isvisible,
.headerRecipe.isvisible {
    opacity: 1;
}

.headerCategoryImage,
.headerCategory .ribbonCategory {
    transition: $trans1000e;
    opacity: 0;
    transform: translate(0%, 7rem) !important;
}
.headerCategoryImage.isvisible,
.headerCategory.isvisible .ribbonCategory {
    transform: translate(0%, 0) !important;
    opacity: 1;
}

.headerRecipeImage {
    position: absolute;
    right: 0;
    z-index: 3;
    top: 50%;
    width: 70%;
    transition: $trans1000e;
    transform: translate(100%, -50%) !important;

    @include media-breakpoint-down(xl) {
        position: relative;
        right: 0;
        z-index: 3;
        top: 50%;
        width: 70%;
        transition: $trans1000e;
        transform: translate(100%, -10%) !important;
    }
}
.headerRecipeImage.isvisible {
    transform: translate(50%, -50%) !important;
    opacity: 1;
    @include media-breakpoint-down(xl) {
        transform: translate(15%, -10%) !important;
    }
}

.container-snapper {
    scroll-snap-type: y mandatory;
}

.child-snapper {
    scroll-snap-align: start;
}

.FormContatti textarea {
    resize: none;
}

.transform-bottom100-y {
    transform: translateY(100%);
}
.img-md-contained {
    width: 100%;
    @include media-breakpoint-up(md) {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.bkg_giallo_cliente2_low {
    @include media-breakpoint-down(md) {
        background-color: $giallo_cliente2 !important;
    }
}

$bollicine-map: (
    1: #efe550,
    2: #0f5471,
    3: #f5ab94,
    4: #efe550,
    5: #125674,
) !default;

@each $k, $v in $bollicine-map {
    .bollicine .circle#{$k} {
        background: $v;
        border-radius: 50%;
    }
}
.bollicine div[class^="circle"]:nth-child(even) {
    left: 48%;
    transform: translate(-50%, 50%);
}
.bollicine div[class^="circle"]:nth-child(odd) {
    right: 48%;
    transform: translate(50%, 50%);
}

@each $k, $v in $bollicine-map {
    $i: $k - 1;
    .bollicineanimate:hover .circle#{$k} {
        -webkit-animation: bollicina#{$k} 5s ease-out #{$i}s 1;
        -moz-animation: bollicina#{$k} 5s ease-out #{$i}s 1;
        -o-animation: bollicina#{$k} 5s ease-out #{$i}s 1;
        animation: bollicina#{$k} 5s ease-out #{$i}s 1;
    }

    @keyframes bollicina#{$k} {
        0% {
            bottom: 0;
            width: 5px;
            height: 5px;
            opacity: 0;
        }
        1% {
            bottom: 0;
            width: 5px;
            height: 5px;
            opacity: 1;
        }
        99% {
            bottom: 100%;
            width: 25px;
            height: 25px;
            opacity: 0.5;
        }
        100% {
            bottom: 100%;
            width: 25px;
            height: 25px;
            opacity: 0;
        }
    }
}

.bouncing-arrow .arrow-right {
    transform: translate(-100%, 0);
}

.bouncing-arrow:hover .arrow-right {
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
}

@-moz-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    40% {
        -moz-transform: translateX(-75%);
        transform: translateX(-75%);
    }
    60% {
        -moz-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
@-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    40% {
        -webkit-transform: translateX(-75%);
        transform: translateX(-75%);
    }
    60% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    40% {
        -moz-transform: translateX(-75%);
        -ms-transform: translateX(-75%);
        -webkit-transform: translateX(-75%);
        transform: translateX(-75%);
    }
    60% {
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

$padding-vh-sizes-map: (
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
) !default;

@each $k, $v in $padding-vh-sizes-map {
    .pyvh-#{$k} {
        padding-top: #{$v}vh !important;
        padding-bottom: #{$v}vh !important;
    }
}
@each $name, $value in $grid-breakpoints {
    @media (min-width: $value) {
        @each $k, $v in $padding-vh-sizes-map {
            .pyvh-#{$name}-#{$k} {
                padding-top: #{$v}vh !important;
                padding-bottom: #{$v}vh !important;
            }
        }
    }
}

.cursorspecial {
    cursor: none;
}
.cursorspecialpointer {
    position: absolute;
    z-index: 99;
    pointer-events: none;
}
.cursorspecialpointer span {
    opacity: 0;
    color: $giallo_cliente;
    transition: $trans200;
    transform: scale(0);
}
.cursorspecialpointer span.cursorspecialpointervisible {
    opacity: 1;
    transform: scale(1);
}

.animate-on-hover.btn_verde-cliente_bianco {
    transition: $trans200;
    color: $bianco;
    background-color: $verde_cliente;
}
.animate-on-hover.btn_verde-cliente_bianco:hover {
    color: $verde_cliente;
    background-color: $grigio_chiaro4_cliente;
}

.videoRicetta {
    display: block;
    background-color: black;
}
.videoRicetta img {
    width: 100%;
    max-width: 30vw;
    margin: 0 auto;
    display: block;
    @include media-breakpoint-down(md) {
        max-width: 80vw;
    }
}

.rotate-45 {
    transform: rotate(-45deg);
    top: 75px;
    left: -5px;
}

.swal2-actions.swal_return_actions button {
    cursor: pointer !important;
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 700 !important;
    margin: 10px !important;
}
.swal2-container {
    padding: 0 !important;
    border-radius: 0 !important;
}
.swal2-popup .confirm_carrello,
.swal2-popup .confirm_carrello:hover,
.swal2-popup .confirm_carrello:focus,
.swal2-popup .confirm_carrello:after,
.swal2-popup .cancel_carrello,
.swal2-popup .cancel_carrello:hover,
.swal2-popup .cancel_carrello:focus,
.swal2-popup .cancel_carrello:after {
    border-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;
    opacity: 1 !important;
}

.swal2-popup,
.swalspinner_messages.swal2-container.swal2-shown {
    border-radius: 0 !important;
    border: 2px solid $verde_cliente !important;
}

.swal_return_message.swal2-container.swal2-shown {
    border: 2px solid $verde_cliente !important;
}

.carbadge[data-count]:after {
    position: absolute;
    top: 1%;
    content: attr(data-count);
    font-size: 60%;
    padding: 0.6em;
    border-radius: 999px;
    line-height: 0.75em;
    color: white;
    background: rgba(255, 0, 0, 0.85);
    text-align: center;
    min-width: 2em;
    font-weight: bold;
}
.nav-search-top span.carbadge {
    color: $grigio_chiaro_cliente;
}

.recipe-image-principal {
    @include media-breakpoint-down(md) {
        border-right: 0 !important;
    }
}

.ribbonCategory {
    @include media-breakpoint-down(sm) {
        top: -50% !important;
    }
}

.cliente-top-5 {
    top: 5%;
}

.border-verde-staticcliente {
    border-bottom: 1px solid $verde_cliente;
    border-top: 0px solid $verde_cliente;
    @include media-breakpoint-down(lg) {
        border-top: 1px solid $verde_cliente;
        border-bottom: 0px solid $verde_cliente;
    }
}
.qtyfixedw {
    max-width: calc(100% - 200px);
}

.invalid-feedback {
    pointer-events: none;
}
html.rtl .forcedfakertl.invalid-feedback {
    text-align: left !important;
}

.headerCategoryImage .immaginegrowing1 {
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: $trans2000eo;
    transition-delay: 1s;
    scale: 0%;
    /*width: 50%;*/
    @include media-breakpoint-down(xl) {
        width: 30%;
    }
    @include media-breakpoint-down(md) {
        width: 30%;
    }
    @include media-breakpoint-down(sm) {
        width: 25%;
    }
}

.headerCategoryImage .immaginegrowing2 {
    opacity: 0;
    transform: translate(50%, 50%);
    transition: $trans2000eo;
    transition-delay: 1s;
    scale: 0;
    /*width: 50%;*/
    @include media-breakpoint-down(xl) {
        width: 30%;
    }
    @include media-breakpoint-down(md) {
        width: 30%;
    }
    @include media-breakpoint-down(sm) {
        width: 25%;
    }
}

.headerCategoryImage.isvisible .immaginegrowing1 {
    opacity: 1;
    transform: translate(-70%, -70%);
    scale: 1;
}
.headerCategoryImage.isvisible .immaginegrowing2 {
    opacity: 1;
    transform: translate(70%, 70%);
    scale: 1;
}

.overflow-x-hidden {
    overflow-x: hidden;
}
.classcategory-1.immaginegrowing1 {
    left: 18% !important;
    top: 40% !important;
}
.classcategory-1.immaginegrowing2 {
    right: 18% !important;
    bottom: 40% !important;
}

.classcategory-3.immaginegrowing1 {
    left: 10% !important;
    top: 10% !important;
}
.classcategory-3.immaginegrowing2 {
    right: 20% !important;
    bottom: 35% !important;
}

.classcategory-4.immaginegrowing1 {
    left: 10% !important;
    top: 30% !important;
}
.classcategory-4.immaginegrowing2 {
    right: 10% !important;
    bottom: 20% !important;
}

.classcategory-7.immaginegrowing1 {
    left: 8% !important;
    top: 20% !important;
}
.classcategory-7.immaginegrowing2 {
    right: 8% !important;
    bottom: 15% !important;
}

.classcategory-8.immaginegrowing1 {
    left: 0% !important;
    top: 20% !important;
}
.classcategory-8.immaginegrowing2 {
    right: 10% !important;
    bottom: 30% !important;
}
.greyscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.languageselector {
    color: $nero;
    background-color: transparent;
    border: none;
}
.languageselector option {
    text-transform: uppercase;
    color: $blu_cliente;
}
.testata-mask {
    transform: translateY(50%);
}

.button_giallo {
    border: 1px solid $giallo_cliente;
    background-color: $bianco;
    color: $grigio_scuro_cliente;
    transition: $trans200;
}
.button_giallo:hover {
    border: 1px solid $giallo_cliente;
    background-color: $giallo_cliente;
    color: $bianco;
}

.button_transparent {
    border: 1px solid $bianco;
    background-color: transparent;
    color: $bianco;
    transition: $trans200;
}
.button_transparent:hover {
    border: 1px solid $giallo_cliente;
    background-color: $giallo_cliente;
    color: $nero;
}

.button_white {
    border: 1px solid $bianco;
    background-color: $bianco;
    color: $giallo_cliente;
    transition: $trans200;
}
.button_white:hover {
    border: 1px solid $bianco;
    background-color: $giallo_cliente;
    color: $bianco;
}

.button_grigio {
    border: 1px solid $grigio_chiaro_cliente;
    background-color: transparent;
    color: $grigio_chiaro_cliente;
    transition: $trans200;
}
.button_grigio:hover {
    border: 1px solid $grigio_chiaro_cliente;
    background-color: $grigio_chiaro_cliente;
    color: $bianco;
}

.button_giallo2 {
    border: 1px solid $giallo_cliente;
    background-color: $giallo_cliente;
    color: $bianco;
    transition: $trans200;
}
.button_giallo2:hover {
    border: 1px solid $giallo_cliente;
    background-color: $bianco;
    color: $giallo_cliente;
}

.button_white2 {
    border: 1px solid $verdemedio_cliente;
    background-color: $bianco;
    color: $verdemedio_cliente;
    transition: $trans200;
}
.button_white2:hover {
    border: 1px solid $verdemedio_cliente;
    background-color: $verdemedio_cliente;
    color: $bianco;
}

.button_grigio2 {
    border: 1px solid $grigio_chiaro_cliente;
    background-color: $grigio_chiaro_cliente;
    color: $bianco;
    transition: $trans200;
}
.button_grigio2:hover {
    border: 1px solid $grigio_chiaro_cliente;
    background-color: transparent;
    color: $grigio_chiaro_cliente;
}

.hover_to_gray {
    transition: $trans200;
}
.hover_to_gray:hover {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.filter-grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.img-contained {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100% !important;
    width: 100% !important;
}

.img-contained2 {
    -o-object-fit: contain;
    object-fit: contain;
    height: 100% !important;
    width: 100% !important;
}

.triangle-white-low {
    margin-bottom: -1.4%;
    @include media-breakpoint-down(md) {
        margin-bottom: -2%;
    }
}

.show-on-hover {
    opacity: 0;
    transition: $trans200;
}

.show-on-hover:hover {
    opacity: 1;
}

.three-color_background {
    height: auto;
    /*width: 100vw; */
    background-image: linear-gradient(
        $bianco 5rem,
        $grigio_chiaro5_cliente 5rem,
        $grigio_chiaro5_cliente calc(100% - 5rem),
        $grigio_scuro_cliente calc(100% - 5rem)
    );
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.two-color_background {
    height: auto;
    /*width: 100vw; */
    background-image: linear-gradient(
        $bianco 5rem,
        $grigio_chiaro5_cliente 5rem
    );
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.two-color_background_half {
    height: auto;
    /*width: 100vw; */
    background-image: linear-gradient($grigio_chiaro5_cliente 50%, $bianco 50%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.two-color_background2 {
    height: auto;
    /*width: 100vw; */
    background-image: linear-gradient(
        $grigio_chiaro5_cliente calc(100% - 3px),
        $verdeacqua_cliente calc(100% - 3px)
    );
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.two-color_background3 {
    height: auto;
    /*width: 100vw; */
    background-image: linear-gradient(
        $bianco calc(100% - 3px),
        $verdeacqua_cliente calc(100% - 3px)
    );
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.prodotti-prospace {
    height: 70% !important;
}

.h-1px {
    height: 1px;
}
.h-3px {
    height: 3px;
}
.border-bianco-1 {
    border: 1px solid $bianco;
}
.border-verdemedio_cliente-1 {
    border: 1px solid $verdemedio_cliente;
}
.border-collapse {
    border: collapse;
}
.tabledati td {
    padding: 1rem;
    border: 1px solid $verdemedio_cliente;
}
.card-shadow {
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.22);
}

.img-flip-hor {
    transform: rotateY(180deg); // Rotate 180 degrees along the y-axis
}

.img-flip-vert {
    transform: rotateX(180deg); // Rotate 180 degrees along the x-axis
}

.img-flip-hor-vert {
    transform: rotateX(180deg) rotateY(180deg); // Rotate 180 degrees on both
}

.col-12-i {
    width: 100% !important;
}
.col-6-i {
    width: 50% !important;
}
.col-4-i {
    width: calc(100% / 3) !important;
}

.tabella-caratteristiche table,
.tabella-capacita table {
    width: 100%;
    border: collapse;
}
.tabella-caratteristiche table tr:first-child td,
.tabella-capacita table tr:first-child td {
    color: $bianco;
    background-color: $verdechiaro_cliente;
}
.tabella-caratteristiche table tr td:nth-child(2),
.tabella-capacita table tr td:nth-child(2),
.tabella-caratteristiche table tr td:nth-child(3),
.tabella-capacita table tr td:nth-child(3) {
    text-align: right;
}

.tabella-caratteristiche table tr td:nth-child(1),
.tabella-capacita table tr td:nth-child(1) {
    width: 60%;
}
.tabella-caratteristiche table tr td:nth-child(2),
.tabella-capacita table tr td:nth-child(2) {
    width: 20%;
}
.tabella-caratteristiche table tr td:nth-child(3),
.tabella-capacita table tr td:nth-child(3) {
    width: 20%;
}

.tabella-caratteristiche table td,
.tabella-capacita table td {
    padding: 1rem;
    border: 1px solid $verdemedio_cliente;
    @include media-breakpoint-down(sm) {
        padding: 0.3rem;
    }
}

.tabella-pdf.tabella-caratteristiche table td,
.tabella-pdf.tabella-capacita table td {
    padding: 0.4rem 0.5rem;
    border: 1px solid $verdemedio_cliente;
}

.max-w-20 {
    max-width: 20%;
}

.btn_blu_bianco {
    background-color: $blu_cliente;
    color: $bianco;
    transition: $trans200;
}

.btn_blu_bianco:hover {
    filter: brightness(130%);
}

.btn_bianco_blu {
    background-color: $bianco;
    border: 1px solid $blu_cliente;
    transition: $trans200;
    width: 90% !important;
    @include media-breakpoint-down(md) {
        width: 100% !important;
    }
}

.btn_bianco_blu:hover {
    width: 100% !important;
}

.btn_grigio_nero {
    background-color: $grigio_chiaro_cliente;
    border: 1px solid $grigio_chiaro_cliente;
    color: $nero;
    transition: $trans200;
}

.btn_grigio_nero:hover {
    filter: brightness(130%);
}

.bkg_bianco_nero_70 {
    background: linear-gradient(90deg, $nero 60%, $bianco 60%);
    @include media-breakpoint-down(xl) {
        background: linear-gradient(90deg, $nero 50%, $bianco 50%);
    }
    @include media-breakpoint-down(lg) {
        background: transparent;
    }
}

.bkg_bianco_nero_70 .fasciaHome2 {
    @include media-breakpoint-down(xl) {
        background: $nero;
    }
}

.bkgv_bianco_grigio_70 {
    background: linear-gradient(0deg, $grigio_chiaro_cliente 50%, $bianco 50%);
    @include media-breakpoint-down(lg) {
        background: linear-gradient(
            0deg,
            $grigio_chiaro_cliente 80%,
            $bianco 80%
        );
    }
}

.bkg_grigio_chiaro_bianco_40 {
    background: linear-gradient(90deg, $grigio_chiaro_cliente 40%, $bianco 40%);
}

.fasciaHomeImmagine2 {
    top: 60%;
    transform: translate(-100%, -50%);
    left: 60%;

    @include media-breakpoint-down(xl) {
        right: 25%;
        transform: translateX(50%);
        bottom: 5%;
        left: auto;
        top: auto;
    }
}

.fasciaProduzioneImmagine5 {
    top: 50%;
    transform: translate(-100%, -50%);
    left: 60%;

    @include media-breakpoint-down(xl) {
        right: 25%;
        transform: translateX(50%);
        bottom: 5%;
        left: auto;
        top: auto;
    }
}
.greenBend {
    height: 0px;
    padding: 15% 0;
    @include media-breakpoint-down(md) {
        padding: 3% 0;
    }
}

.greenBend2 {
    height: 0px;
    padding: 15% 0;
    @include media-breakpoint-down(lg) {
        padding: 3% 0;
    }
}
.translateCustomRight {
    left: 60px;
    position: relative;
    top: 30px;
}

.translateCustomRight2bis {
    left: 60px;
    position: relative;
}
.translateCustomLeft2 {
    left: -15%;
    position: relative;
    top: -200px;
    @include media-breakpoint-down(xxxl) {
        left: -15%;
    }
    @include media-breakpoint-down(md) {
        left: auto;
        top: auto;
    }
}
.translateCustomLeft3 {
    left: -10%;
    position: relative;
    bottom: -5%;
    transform: translateY(-100%);

    @include media-breakpoint-down(md) {
        left: auto;
        bottom: auto;
        transform: translateY(0%);
    }
}
.translateCustomRight2 {
    right: -10%;
    position: absolute;
    top: -5%;
    @include media-breakpoint-down(xxxl) {
        right: -13%;
    }
    @include media-breakpoint-down(md) {
        right: auto;
        top: auto;
    }
}

.fasciaProduzione5 {
    @include media-breakpoint-down(xl) {
        background-color: $nero;
    }
}
.canvasLeft {
    position: relative;
    left: -50px;
}
.canvasRight {
    position: relative;
    right: -50px;
}

.imgFasciaAzienda1 {
    transform: translateX(20%);
    @include media-breakpoint-down(lg) {
        transform: translateX(0%);
        padding-left: 20%;
    }
    @include media-breakpoint-down(sm) {
        transform: translateX(0%);
    }
}

.class-centered-text b,
.class-centered-text strong {
    color: $blu_cliente;
}
.buttonform {
    border: none;
    background-color: transparent;
}

.bannerfooterimage {
    width: auto;
    max-width: 100%;
}

@media (max-width: 767.98px) {
    .bannerfooterimage {
        width: 100%;
    }
}

.bannerfooterimagewrapper {
    z-index: 2;
}

.bannerfooterimagewrapper:not(.active) {
    display: none;
}
