/* purgecss start ignore */
#colorbox,
#cboxOverlay,
#cboxWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}
#cboxWrapper {
    max-width: none;
}
#cboxOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
}
#cboxMiddleLeft,
#cboxBottomLeft {
    clear: left;
}
#cboxContent {
    position: relative;
}
#cboxLoadedContent {
    overflow: auto;
}
#cboxTitle {
    margin: 0;
}
#cboxLoadingOverlay,
#cboxLoadingGraphic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
    cursor: pointer;
}
.cboxPhoto {
    float: left;
    margin: auto;
    border: 0;
    display: block;
    max-width: none;
    -ms-interpolation-mode: bicubic;
}
.cboxIframe {
    width: 100%;
    height: 100%;
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
}
#colorbox,
#cboxContent,
#cboxLoadedContent {
    box-sizing: content-box;
}

#cboxOverlay {
    background: #000;
    opacity: 0.9;
    filter: alpha(opacity = 90);
}
#colorbox {
    outline: 0;
}
#cboxContent {
    margin-top: 20px;
    background: #000;
}
.cboxIframe {
    background: #fff;
}
#cboxError {
    padding: 50px;
    border: 1px solid #ccc;
}
#cboxLoadedContent {
    border: 5px solid #000;
    background: #fff;
}
#cboxTitle {
    position: absolute;
    top: -20px;
    left: 0;
    color: #ccc;
}
#cboxCurrent {
    position: absolute;
    top: -20px;
    right: 0px;
    color: #ccc;
}
#cboxLoadingGraphic {
    background: url("../images/loading.gif") no-repeat center center;
}
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
    border: 0;
    padding: 0;
    margin: 0;
    overflow: visible;
    width: auto;
    background: none;
}
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
    outline: 0;
}
#cboxSlideshow {
    position: absolute;
    top: -20px;
    right: 90px;
    color: #fff;
}
#cboxPrevious {
    position: absolute;
    top: 50%;
    left: 5px;
    margin-top: -32px;
    background: url("../images/controls.png") no-repeat top left;
    width: 28px;
    height: 65px;
    text-indent: -9999px;
}
#cboxPrevious:hover {
    background-position: bottom left;
}
#cboxNext {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -32px;
    background: url("../images/controls.png") no-repeat top right;
    width: 28px;
    height: 65px;
    text-indent: -9999px;
}
#cboxNext:hover {
    background-position: bottom right;
}
#cboxClose {
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    background: url("../images/controls.png") no-repeat top center;
    width: 38px;
    height: 19px;
    text-indent: -9999px;
}
#cboxClose:hover {
    background-position: bottom center;
}
/* purgecss end ignore */
