.footer {
    text-align: center;
}
.footer_payoff {
    display: $d-flex;
    flex-direction: $flex-direction-column;
    align-items: $flex-content-center;
}
.footer_cliente {
    background-color: $grigio_scuro_cliente;
    color: $white;
    width: 100%;
    max-width: 2000px;
}
.footer_cliente .col-12 {
    display: $d-flex;
    justify-content: $flex-content-center;
    align-items: $flex-content-center;
}
.socials_footer {
    display: $d-flex;
    justify-content: $flex-content-center;
    align-items: $flex-content-center;
}
.socials_footer span {
    background-color: $arancione_cliente;
    width: 32px;
    height: 32px;
    display: $d-flex;
    justify-content: $flex-content-center;
    align-items: $flex-content-center;
    margin: $p-1;
    transition: $trans200;
}
.socials_footer a {
    color: $white;
}
.socials_footer span:hover {
    background-color: darken($arancione_cliente, 20%);
}

.halftonerecensione {
    @include media-breakpoint-down(md) {
        background-color: rgba(0, 0, 0, 0.5);
    }
}
