body {
    font-family: $font-regular;
    overflow-x: hidden;
    color: $nero;
    height: 100vh;
    background-color: $bianco;

    @include media-breakpoint-down(sm) {
        font-size: $font-size-15;
    }
}

html.rtl .forcedfakertl {
    text-align: right !important;
    direction: initial !important;
    unicode-bidi: isolate !important;
}
html.rtl .forcedfakertl.text-center {
    text-align: center !important;
    direction: initial !important;
    unicode-bidi: isolate !important;
}

img {
    max-width: 100%;
    align-self: $flex-start;
    transition: $trans200;
}

.rtl img.flipimage {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    //color: $bianco;
    transition: $trans200;
    text-decoration: none;
    color: inherit;
}

a:hover {
    //color: $bianco;
    text-decoration: none;
    color: inherit;
}

/* forzatura container a 1140px per sito piccolo */
@media (min-width: 1200px) {
    #main_body_container .container {
        max-width: 1140px;
    }
}

.z-index-2 {
    z-index: 2;
}
.z-index-3 {
    z-index: 3;
}
.z-index-4 {
    z-index: 4;
}
.z-index-5 {
    z-index: 5 !important;
}

/* forzatura container min-width: 320px per evitare dimensionamento eccessivo */
@include media-breakpoint-down(sm) {
    header,
    .container {
        min-width: 320px;
    }

    .logo-header {
        min-width: 140px;
    }
    .container:not(.avoid-p-forced) {
        padding: 0 1rem;
    }
    .avoid-p-forced.yellow {
        background-color: $giallo_cliente;
    }
}
