.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img:not(.swiperIcon) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-home-settori .swiper-slide {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: start;
    align-items: start;
    width: 40% !important;
    @include media-breakpoint-down(md) {
        width: 100% !important;
    }
}
.swiper-home-settori .swiper-button-next2,
.swiper-home-settori .swiper-button-prev2 {
    bottom: -60px;
    position: absolute;
}

.swiper-statica-slides .swiper-button-next2,
.swiper-statica-slides .swiper-button-prev2 {
    bottom: $spacer * 5;
    position: absolute;
    @include media-breakpoint-down(md) {
        bottom: $spacer;
    }
}

.swiper-home-settori .swiper-button-next2,
.swiper-statica-slides .swiper-button-next2 {
    left: 60%;
    right: auto;
}
.swiper-home-settori .swiper-button-prev2,
.swiper-statica-slides .swiper-button-prev2 {
    right: 60%;
    left: auto;
}
.swiper-home-settori .swiper-button-disabled,
.swiper-statica-slides .swiper-button-disabled {
    display: none;
}
