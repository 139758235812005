.verdechiaro_cliente {
    color: $verdechiaro_cliente !important;
}
.grigio_scuro_cliente {
    color: $grigio_scuro_cliente !important;
}

.grigio_chiaro_cliente {
    color: $grigio_chiaro_cliente !important;
}

.grigio_chiaro2_cliente {
    color: $grigio_chiaro2_cliente !important;
}

.grigio_chiaro3_cliente {
    color: $grigio_chiaro3_cliente !important;
}

.grigio_chiaro4_cliente {
    color: $grigio_chiaro4_cliente !important;
}

.arancione_cliente {
    color: $arancione_cliente !important;
    --inherited-color: #ea4a00 !important;
}

.verde_cliente {
    color: $verde_cliente !important;
    --inherited-color: #104124 !important;
}

.giallo_cliente {
    color: $giallo_cliente !important;
    --inherited-color: rgba(255, 234, 54, 1) !important;
}

.giallo_cliente2 {
    color: $giallo_cliente !important;
    --inherited-color: #fcdc2f !important;
}

.blu_cliente {
    color: $blu_cliente !important;
}
.bianco {
    color: $bianco !important;
}

.nero {
    color: $nero !important;
}

.verdemedio_cliente {
    color: $verdemedio_cliente !important;
}

.bkg_grigio_scuro_cliente {
    background-color: $grigio_scuro_cliente !important;
}

.bkg_grigio_chiaro_cliente {
    background-color: $grigio_chiaro_cliente !important;
}

.bkg_grigio_chiaro2_cliente {
    background-color: $grigio_chiaro2_cliente !important;
}

.bkg_grigio_chiaro3_cliente {
    background-color: $grigio_chiaro3_cliente !important;
}

.bkg_grigio_chiaro4_cliente {
    background-color: $grigio_chiaro4_cliente !important;
}

.bkg_arancione_cliente {
    background-color: $arancione_cliente !important;
}

.bkg_verde_cliente {
    background-color: $verde_cliente !important;
}

.bkg_giallo_cliente {
    background-color: $giallo_cliente !important;
}

.bkg_giallo_cliente2 {
    background-color: $giallo_cliente2 !important;
}

.bkg_blu_cliente {
    background-color: $blu_cliente !important;
}
.bkg_bianco {
    background-color: $bianco !important;
}

.bkg_verdechiaro_cliente {
    background-color: $verdechiaro_cliente;
}

.bkg_verdeacqua_cliente {
    background-color: $verdeacqua_cliente;
}

.bkg_grigio_chiaro3_cliente_opaco {
    background-color: $grigio_chiaro3_cliente_opaco;
}

.bkg_grigio_chiaro5_cliente {
    background-color: $grigio_chiaro5_cliente;
}

.bkg_nero {
    background-color: $nero !important;
}

.bkg_verdergba_cliente {
    background-color: $verdergba_cliente !important;
}

.bkg_verdemedio_cliente {
    background-color: $verdemedio_cliente !important;
}

.bkg_trasparente {
    background-color: transparent !important;
}
