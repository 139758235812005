/* Chrome, Safari, Edge, Opera */
.buttoned input[type="number"]::-webkit-outer-spin-button,
.buttoned input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.buttoned input[type="number"] {
    -moz-appearance: textfield;
}

.boxProductDetail .buttoned {
    display: flex;
    align-items: stretch;
    height: 94px;
    a {
        display: flex;
        padding: 0 35px;
        align-items: center;
        color: $verde_cliente;
    }
}
