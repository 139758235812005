@include font-face(
    UbuntuRegular,
    "../webfonts/Ubuntu-Regular",
    400,
    normal,
    woff2
);
@include font-face(UbuntuBold, "../webfonts/Ubuntu-Bold", 700, normal, woff2);
@include font-face(
    UbuntuBoldItalic,
    "../webfonts/Ubuntu-BoldItalic",
    700,
    normal,
    woff2
);
@include font-face(UbuntuLight, "../webfonts/Ubuntu-Light", 300, normal, woff2);

// Typography
$font-regular: "UbuntuRegular", sans-serif;
$font-bolditalic: "UbuntuBoldItalic", sans-serif;
$font-bold: "UbuntuBold", sans-serif;
$font-light: "UbuntuLight", sans-serif;

/**
* FONT FAMILIES
*/
.font-regular {
    font-family: $font-regular;
}
.font-bolditalic {
    font-family: $font-bolditalic;
}
.font-bold {
    font-family: $font-bold;
}
.font-light {
    font-family: $font-light;
}
// =============================================================================
// Font size responsive xs sm md lg xl xxl fhd uhd (vamosss)
// =============================================================================

@each $k, $v in $font-sizes-map {
    .fs-#{$k} {
        font-size: $v !important;
    }
}

@each $name, $value in $grid-breakpoints {
    @media (min-width: $value) {
        @each $k, $v in $font-sizes-map {
            .fs-#{$name}-#{$k} {
                font-size: $v !important;
            }
        }
    }
}

@include font-face(icomoon, "../webfonts/icomoon", normal, normal, woff);
/* purgecss start ignore */
$font-icomoon: "icomoon", sans-serif;

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-icomoon !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
    content: "\e900";
}
.icon-arrow-up:before {
    content: "\e902";
}
.icon-arrow-right:before {
    content: "\e901";
}
.icon-arrow-left:before {
    content: "\e903";
}
/* purgecss end ignore */
