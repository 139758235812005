$accent: $blu_cliente;
$accent-inactive: $grigio_chiaro_cliente;
$secondary: $accent-inactive;

/* tab setting */
$tab-count: 4;
$indicator-width: 100%;
$indicator-height: 20px;

/* breakpoints */
$breakpoints: (
    medium: #{$tab-count * 250px},
    small: #{$tab-count * 150px},
);

/* selectors relative to radio inputs */
$label-selector: "~ ul > li";
$slider-selector: "~ .sliderwrapper > .slider";
$content-selector: "~ .content > section";
@mixin user-select($value) {
    -webkit-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    -o-user-select: $value;
    user-select: $value;
}
@mixin tabs(
    $label-selector: $label-selector,
    $slider-selector: $slider-selector,
    $content-selector: $content-selector
) {
    @for $i from 1 through $tab-count {
        &:nth-of-type(#{$i}):checked {
            #{$label-selector}:nth-child(#{$i}) {
                @content;
            }

            #{$slider-selector} {
                transform: translateX(#{100% * ($i - 1)});
            }

            #{$content-selector}:nth-child(#{$i}) {
                display: block;
            }
        }
    }
}

.tabs {
    position: relative;
    width: 100%;
    height: max-content;
    min-width: #{$tab-count * 60px};
    input[name="tab-control"] {
        display: none;
    }

    .content section h2,
    ul li label {
        color: $accent;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;

        li {
            box-sizing: border-box;
            flex: 1;
            width: calc(100% / #{$tab-count});
            padding: 0 10px;
            text-align: center;

            label {
                transition: all 0.3s ease-in-out;
                color: $secondary;
                padding: 5px auto;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                white-space: nowrap;
                -webkit-touch-callout: none;
                @include user-select(none);

                br {
                    display: none;
                }

                svg {
                    fill: $secondary;
                    height: 1.2em;
                    vertical-align: bottom;
                    margin-right: 0.2em;
                    transition: all 0.2s ease-in-out;
                }

                &:hover,
                &:focus,
                &:active {
                    outline: 0;
                    color: lighten($secondary, 15%);
                    svg {
                        fill: lighten($secondary, 15%);
                    }
                }
            }
        }
    }

    .slider {
        position: relative;
        width: calc(100% / #{$tab-count});
        transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
        .indicator {
            position: relative;
            width: $indicator-width;
            max-width: 100%;
            margin: 0 auto;
            height: $indicator-height;
            top: $indicator-height;
            background: transparent;
        }
    }

    .content {
        padding-top: 30px;
        border-top: 1px solid $grigio_chiaro_cliente;

        section {
            display: none;
            animation: {
                name: content;
                direction: normal;
                duration: 0.3s;
                timing-function: ease-in-out;
                iteration-count: 1;
            }
            line-height: 1.4;

            h2 {
                color: $accent;
                display: none;
                &::after {
                    content: "";
                    position: relative;
                    display: block;
                    width: 30px;
                    height: 3px;
                    background: $accent;
                    margin-top: 5px;
                    left: 1px;
                }
            }
        }
    }

    input[name="tab-control"] {
        @include tabs {
            > label {
                cursor: default;
                color: $accent;

                svg {
                    fill: $accent;
                }

                @media (max-width: map-get($breakpoints, small)) {
                    background: rgba(0, 0, 0, 0.04);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    @keyframes content {
        from {
            opacity: 0;
            transform: translateY(5%);
        }
        to {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    @media (max-width: map-get($breakpoints, medium)) {
        ul li label {
            white-space: initial;

            br {
                display: initial;
            }

            svg {
                height: 1.5em;
            }
        }
    }

    @media (max-width: map-get($breakpoints, small)) {
        ul li label {
            padding: 5px;
            border-radius: 5px;

            span {
                /*display: none;*/
            }
        }

        .slider {
            display: none;
        }

        .content {
            margin-top: 20px;
            section h2 {
                display: block;
            }
        }
    }
}
