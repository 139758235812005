/* CUSTOM FONT SIZE */
$font-size-10: $font-size-base * 0.625 !default;
$font-size-11: $font-size-base * 0.6875 !default;
$font-size-12: $font-size-base * 0.75 !default;
$font-size-13: $font-size-base * 0.8125 !default;
$font-size-14: $font-size-base * 0.875 !default;
$font-size-15: $font-size-base * 0.9375 !default;
$font-size-16: $font-size-base * 1 !default;
$font-size-17: $font-size-base * 1.0625 !default;
$font-size-18: $font-size-base * 1.125 !default;
$font-size-19: $font-size-base * 1.1875 !default;
$font-size-20: $font-size-base * 1.25 !default;
$font-size-22: $font-size-base * 1.375 !default;
$font-size-24: $font-size-base * 1.5 !default;
$font-size-25: $font-size-base * 1.5625 !default;
$font-size-26: $font-size-base * 1.625 !default;
$font-size-28: $font-size-base * 1.75 !default;
$font-size-30: $font-size-base * 1.875 !default;
$font-size-32: $font-size-base * 2 !default;
$font-size-34: $font-size-base * 2.125 !default;
$font-size-35: $font-size-base * 2.1875 !default;
$font-size-36: $font-size-base * 2.25 !default;
$font-size-38: $font-size-base * 2.375 !default;
$font-size-40: $font-size-base * 2.5 !default;
$font-size-44: $font-size-base * 2.75 !default;
$font-size-48: $font-size-base * 3 !default;
$font-size-50: $font-size-base * 3.125 !default;
$font-size-52: $font-size-base * 3.25 !default;
$font-size-56: $font-size-base * 3.5 !default;
$font-size-60: $font-size-base * 3.75 !default;
$font-size-70: $font-size-base * 4.375 !default;
$font-size-80: $font-size-base * 5 !default;
$font-size-90: $font-size-base * 5.625 !default;
$font-size-100: $font-size-base * 6.25 !default;
$font-size-110: $font-size-base * 6.875 !default;
$font-size-115: $font-size-base * 7.187 !default;
$font-size-120: $font-size-base * 7.5 !default;
$font-size-130: $font-size-base * 8.125 !default;
$font-size-200: $font-size-base * 12.5 !default;

$font-size-250: $font-size-base * calc(250 / 16) !default;
$font-size-300: $font-size-base * calc(300 / 16) !default;
$font-size-350: $font-size-base * calc(350 / 16) !default;
$font-size-400: $font-size-base * calc(400 / 16) !default;

$font-sizes-map: (
    10: $font-size-10,
    11: $font-size-11,
    12: $font-size-12,
    13: $font-size-13,
    14: $font-size-14,
    15: $font-size-15,
    16: $font-size-16,
    17: $font-size-17,
    18: $font-size-18,
    19: $font-size-19,
    20: $font-size-20,
    22: $font-size-22,
    24: $font-size-24,
    25: $font-size-25,
    26: $font-size-26,
    28: $font-size-28,
    30: $font-size-30,
    32: $font-size-32,
    34: $font-size-34,
    35: $font-size-35,
    36: $font-size-36,
    38: $font-size-38,
    40: $font-size-40,
    44: $font-size-44,
    48: $font-size-48,
    50: $font-size-50,
    52: $font-size-52,
    56: $font-size-56,
    60: $font-size-60,
    70: $font-size-70,
    80: $font-size-80,
    90: $font-size-90,
    100: $font-size-100,
    110: $font-size-110,
    115: $font-size-115,
    120: $font-size-120,
    130: $font-size-130,
    200: $font-size-200,

    250: $font-size-200,
    300: $font-size-300,
    350: $font-size-350,
    400: $font-size-400,
) !default;

.ls-01 {
    letter-spacing: 0.1em !important;
}
.ls-02 {
    letter-spacing: 0.2em !important;
}
.lh-0-85 {
    line-height: 0.85 !important;
}
.lh-0-9 {
    line-height: 0.9 !important;
}
.lh-0-95 {
    line-height: 0.95 !important;
}
.lh-1 {
    line-height: 1 !important;
}
.lh-1-2 {
    line-height: 1.2 !important;
}
.lh-2 {
    line-height: 2 !important;
}
