/** CARRELLO **/

.shop_table {
    border: 1px solid #ffffff;
    width: 100%;
}
.shop_table > tbody > tr > td,
.shop_table > tbody > tr > th,
.shop_table > tfoot > tr > td,
.shop_table > tfoot > tr > th,
.shop_table > thead > tr > th {
    padding: 10px;
    border-top: 4px solid #ffffff;
}
.shop_table > tfoot,
.shop_table > thead {
    background-color: #edf5fd;
}

.btn-carrello-top {
    font-weight: bold;
    background-color: #ededed;
    border: 1px solid #ededed;
    color: #999;
}
.btn-carrello-top.btn-attivo {
    background-color: $verde_cliente;
    color: #fff;
}

.shop-cart {
    overflow: hidden;
}
.cart_table_item {
    transition: 0.5s ease;
    transform: translateX(0);
}
.cart_table_item.deleting {
    transform: translateX(-100%);
}
.shop-cart .cart_table_item {
    border-bottom: 1px solid #d9d9d9;
}
.shop-cart .cart_table_title {
    border-bottom: 1px solid #d9d9d9;
}
.shop-cart .cart_table_title .actions {
    width: 100%;
}
.shop-cart .cart_table_title .Product-name,
.shop-cart .cart_table_title .product-price,
.shop-cart .cart_table_title .product-quantity,
.shop-cart .cart_table_title .product-remove,
.shop-cart .cart_table_title .product-subtotal,
.shop-cart .cart_table_title .product-total,
.shop-cart .cart_table_title .product-thumbnail {
    padding: 10px 0;
}
.shop-cart .cart_table_item .Product-name,
.shop-cart .cart_table_item .product-price,
.shop-cart .cart_table_item .product-quantity,
.shop-cart .cart_table_item .product-remove,
.shop-cart .cart_table_item .product-subtotal,
.shop-cart .cart_table_item .product-total,
.shop-cart .cart_table_item .product-thumbnail {
    padding: 10px 0;
}
.shop-cart .cart_table_item .quantity {
    margin: 0;
}
.shop-cart .product-remove {
    width: 6%;
    text-align: center;
}
.shop-cart .product-thumbnail {
    width: 7%;
}
.shop-cart .product-name {
    width: 40%;
    vertical-align: middle;
    padding: 5px 10px;
}
.shop-cart .product-price {
    width: 8%;
}
.shop-cart .product-quantity {
    width: 20%;
    @include media-breakpoint-down(md) {
        width: 30%;
    }
}
.shop-cart .product-quantity {
    text-align: center;
}
.shop-cart .product-subtotal {
    width: 10%;
}
.shop-cart .product-subtotal {
    text-align: right;
}
.shop-cart .product-total {
    width: 10%;
}
.shop-cart .product-total {
    text-align: right;
}

/** TABS **/
#tabs {
    position: relative;
    z-index: 9999;
}
#tabs .tabs-nav {
    border-bottom: 1px solid #dadada;
}
#tabs .tabs-nav .selected {
    background-color: #dadada;
    color: #4a4d4e;
}
#tabs .tabs-nav .selected span {
    display: none;
}
#tabs .tabs-nav span {
    display: block;
    float: right;
}
#tabs .tabs-nav li {
    border: 1px solid #dadada;
    margin-left: 0;
    margin-right: -5px;
    width: 25%;
    box-sizing: border-box;
}
#tabs .tabs-panel {
    background-color: #dadada;
    color: #4a4d4e;
    padding: 3px;
}

.shop-cart.registrazioneBox #tabs .tabs-nav li {
    border: 1px solid #dadada;
    margin-left: 0;
    margin-right: -3px;
    width: 33.3333%;
    box-sizing: border-box;
    font-size: 20px;
    margin-bottom: -4px;
    border-bottom: 1px solid $verde_cliente;
    background-color: #ededed;
}
.shop-cart.registrazioneBox #tabs .tabs-nav li.selected {
    color: red !important;
    border: 1px solid red;
    border-bottom: 1px solid #fff;
    background-color: #fff;
}
.shop-cart.registrazioneBox #tabs .tabs-nav {
    border-bottom: 0px solid $verde_cliente !important;
}
.shop-cart.registrazioneBox #tabs .tabs-panel {
    border: 1px solid $verde_cliente !important;
    float: left;
    background-color: #fff;
    width: 100%;
    line-height: 30px;
    padding: 20px;
}
.shop-cart.registrazioneBox #tabs .tabs-panel .form-login {
    width: 100%;
}
.shop-cart.registrazioneBox #tabs .tabs-nav li i {
    float: left;
    width: 15%;
    font-size: 25px;
}
.shop-cart.registrazioneBox #tabs .tabs-nav li h3 {
    display: inline-table;
    width: 80%;
    float: left;
    margin-left: 5%;
}

#imgcaptcha {
    height: 30px;
}

/** TABS **/

.right-prod {
    background-color: #ededed;
    border: 1px solid #ccc;
    padding: 20px 5px;
    color: #333;
}

.pagamento .shop-cart {
    width: 100% !important;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}

.cart_table_item input[type="number"] {
    width: 80%;
    border: 1px solid #a5aba6;
    padding: 6px 6px 6px 6px;
}
#formRegistrazione input[type="text"],
#formRegistrazione input[type="email"],
#formRegistrazione input[type="password"] {
    border: 1px solid #a5aba6;
    width: 100%;
    padding: 6px 6px 6px 6px;
}
#formRegistrazione .select {
    width: 100%;
}
#formRegistrazione .invalid-feedback {
    width: 100%;
}
#formRegistrazione .select.theme-square.v2 {
    border: none;
}
#formRegistrazione .select > select {
    padding: 6px;
    color: #000;
    border: 1px solid #a5aba6;
    letter-spacing: unset;
}
#formRegistrazione input[type="text"]:focus,
#formRegistrazione input[type="email"]:focus,
#formRegistrazione input[type="password"]:focus,
#formRegistrazione textarea:focus {
    border: 1px solid #a5aba6 !important;
}
#formRegistrazione label[for="Privacy"],
#formRegistrazione label[for="Newsletter"] {
    padding-left: 38px;
    padding-right: 5px;
    float: left;
}
#formRegistrazione label[for="Privacy"] label {
    position: absolute;
    left: 0;
    margin-top: -12px;
}
#formRegistrazione input[type="checkbox"].error + label[for="Privacy"]::before {
    background-color: #ffdddd !important;
    color: #ce0000;
}
#formRegistrazione textarea {
    border: 1px solid #a5aba6;
    width: 95%;
    padding: 6px 6px 6px 6px;
    height: 150px;
}
#formRegistrazione input[type="submit"] {
    cursor: pointer;
    float: right;
}
#formRegistrazione label {
    margin-bottom: 0;
}

.parsley-error input[type="checkbox"] + label::before,
.parsley-error input[type="checkbox"] + label::after,
.parsley-error input[type="radio"] + label::before,
.parsley-error input[type="radio"] + label::after {
    color: #b94a48;
    background-color: #f2dede;
    border: 1px solid #eed3d7;
}

#formLogin input[type="text"],
#formLogin input[type="email"],
#formLogin input[type="password"] {
    border: 1px solid #a5aba6;
    width: 100%;
    padding: 6px 6px 6px 6px;
}
#formLogin .select {
    width: 100%;
}
#formLogin .select.theme-square.v2 {
    border: none;
}
#formLogin .select > select {
    padding: 6px;
    color: #000;
    border: 1px solid #a5aba6;
    letter-spacing: unset;
}
#formLogin input[type="text"]:focus,
#formLogin input[type="email"]:focus,
#formLogin input[type="password"]:focus,
#formLogin textarea:focus {
    border: 1px solid #a5aba6 !important;
}
#formLogin label[for="Privacy"],
#formLogin label[for="Newsletter"] {
    padding-left: 38px;
    padding-right: 5px;
    float: left;
}
#formLogin label[for="Privacy"] label {
    position: absolute;
    left: 0;
    margin-top: -12px;
}
#formLogin input[type="checkbox"].error + label[for="Privacy"]::before {
    background-color: #ffdddd !important;
    color: #ce0000;
}
#formLogin textarea {
    border: 1px solid #a5aba6;
    width: 95%;
    padding: 6px 6px 6px 6px;
    height: 150px;
}
#formLogin input[type="submit"] {
    cursor: pointer;
    float: right;
}
#formLogin label {
    margin-bottom: 0;
}
#formLogin .invalid-feedback {
    width: 100%;
}

#formSpedizione input[type="text"],
#formSpedizione input[type="email"],
#formSpedizione input[type="password"] {
    border: 1px solid #a5aba6;
    width: 100%;
    padding: 6px 6px 6px 6px;
}
#formSpedizione .select {
    width: 100%;
}
#formSpedizione .select.theme-square.v2 {
    border: none;
}
#formSpedizione .select > select {
    padding: 6px;
    color: #000;
    border: 1px solid #a5aba6;
    letter-spacing: unset;
}
#formSpedizione input[type="text"]:focus,
#formSpedizione input[type="email"]:focus,
#formSpedizione input[type="password"]:focus,
#formSpedizione textarea:focus {
    border: 1px solid #a5aba6 !important;
}
#formSpedizione label[for="Privacy"],
#formSpedizione label[for="Newsletter"] {
    padding-left: 38px;
    padding-right: 5px;
    float: left;
}
#formSpedizione label[for="Privacy"] label {
    position: absolute;
    left: 0;
    margin-top: -12px;
}
#formSpedizione input[type="checkbox"].error + label[for="Privacy"]::before {
    background-color: #ffdddd !important;
    color: #ce0000;
}
#formSpedizione textarea {
    border: 1px solid #a5aba6;
    width: 95%;
    padding: 6px 6px 6px 6px;
    height: 150px;
}
#formSpedizione input[type="submit"] {
    cursor: pointer;
    float: right;
}
#formSpedizione label {
    margin-bottom: 0;
}
#formSpedizione .invalid-feedback {
    width: 100%;
}

#formLoginc input[type="text"],
#formLoginc input[type="email"],
#formLoginc input[type="password"] {
    border: 1px solid #a5aba6;
    width: 100%;
    padding: 6px 6px 6px 6px;
}
#formLoginc .select {
    width: 100%;
}
#formLoginc .select.theme-square.v2 {
    border: none;
}
#formLoginc .select > select {
    padding: 6px;
    color: #000;
    border: 1px solid #a5aba6;
    letter-spacing: unset;
}
#formLoginc .invalid-feedback {
    width: 100%;
}
#formLoginc input[type="text"]:focus,
#formLoginc input[type="email"]:focus,
#formLoginc input[type="password"]:focus,
#formLoginc textarea:focus {
    border: 1px solid #a5aba6 !important;
}
#formLoginc label[for="Privacy"],
#formLoginc label[for="Newsletter"] {
    padding-left: 38px;
    padding-right: 5px;
    float: left;
}
#formLoginc label[for="Privacy"] label {
    position: absolute;
    left: 0;
    margin-top: -12px;
}
#formLoginc input[type="checkbox"].error + label[for="Privacy"]::before {
    background-color: #ffdddd !important;
    color: #ce0000;
}
#formLoginc textarea {
    border: 1px solid #a5aba6;
    width: 95%;
    padding: 6px 6px 6px 6px;
    height: 150px;
}
#formLoginc input[type="submit"] {
    cursor: pointer;
    float: right;
}
#formLoginc label {
    margin-bottom: 0;
}

.avvisami_disponibilita {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 20px;
}
.avvisami_disponibilita span {
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 7px;
    margin-top: 15px;
    font-size: 1.5rem;
}
.avvisami_disponibilita span strong {
    color: red;
}
.avvisami_disponibilita label {
    font-size: 14px;
    font-style: italic;
    color: red;
    display: block;
}
.avvisami {
    display: none;
    position: absolute;
    text-align: center;
    width: 20px;
    padding-top: 3px;
    background-color: red;
    top: 0;
    border-radius: 50%;
    height: 20px;
    cursor: pointer;
    left: 0;
    margin-left: 0;
    z-index: 999;
}
.avvisami i {
    color: white;
    cursor: pointer;
    font-size: 1.3rem;
    line-height: 1.3rem;
    display: block;
}
.qtip b {
    background-color: red;
    padding: 0 3px;
}
#avvisamiTaglia {
    margin-bottom: 15px;
    float: left;
    height: 80px;
}
#avvisamiTaglia input[type="email"] {
    float: left;
    width: 70%;
    margin-bottom: 0px;
    height: 39px;
    border: 1px solid red;
    border-radius: 0;
    font-size: 15px;
}
#avvisamiTaglia #SubmitAvviso {
    float: left;
    width: 30%;
    margin-bottom: 0px;
    background-color: red;
    color: white;
    font-size: 15px;
    padding: 0;
    border: 0;
    height: 39px;
    text-align: center;
    padding: 8px 0 0 0;
    cursor: pointer;
}

.theme-square.v2 select {
    color: #9e8c79;
    padding: 9px 34px 9px 12px;
    text-transform: none;
    height: 39px;
    -webkit-font-smoothing: antialiased;
    font-size: 13px;
}
.theme-square.v2 {
    border: 1px solid #a5aba6;
    background: transparent
        url('data:image/svg+xml,%3Csvg xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width%3D"11" height%3D"11" viewBox%3D"0 0 11 11"%3E%3Cpath d%3D"M4.33 8.5L0 1L8.66 1z" fill%3D"%239e8c79"%2F%3E%3C%2Fsvg%3E')
        right 10px center no-repeat;
    background-size: 24px 13px;
}
.nazione .theme-square.v2 {
    height: 30px;
}
.nazione .theme-square.v2 select {
    color: #9e8c79;
    padding: 5px 34px 5px 12px;
    text-transform: none;
    height: 30px;
    -webkit-font-smoothing: antialiased;
    font-size: 13px;
}

input.theme-square.v2,
input.theme-square.v2:focus {
    border: 1px solid #a5aba6 !important;
    color: #9e8c79;
    padding: 13px 13px;
    box-sizing: border-box;
    height: 41px;
}

.pulsanteRosso {
    background-color: transparent;
    color: $verde_cliente;
    padding: 6px 30px;
    font-size: 15px;
    line-height: 22px;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
    border: 2px solid $verde_cliente;
    cursor: pointer;
    border: 2px solid $verde_cliente;
}
.pulsanteRosso:hover {
    background-color: $verde_cliente;
    color: #fff;
    border: 2px solid $verde_cliente;
}

.pulsanteRosso2 {
    background: $verde_cliente;
    color: #ffffff;
    padding: 6px 30px;
    font-size: 15px;
    line-height: 22px;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
    border: 2px solid $verde_cliente;
    cursor: pointer;
}
.pulsanteRosso2:hover {
    background: transparent;
    border: 2px solid $verde_cliente;
    color: $verde_cliente;
}

.pulsanteRosso3 {
    color: $verde_cliente;
    padding: 6px 0px;
    font-size: 15px;
    line-height: 22px;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
    cursor: pointer;
}
.pulsanteRosso3:hover {
    color: #000;
}

span.titletooltip:active:after,
span.titletooltip:hover:after {
    content: attr(data-title);
    padding: 5px;
    border: none;
    top: -30px;
    right: 0;
    background: $verde_cliente;
    position: absolute;
    transform: translateY(-50%);
    color: #fff;
    font-weight: normal;
    font-size: small;
    text-align: left;
    width: max-content;
}
