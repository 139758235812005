.menu-mobile {
    padding: 0 !important;
    flex-direction: $flex-direction-column;
    justify-content: $flex-start;
}

.menu-mobile .swal2-popup {
    flex-direction: $flex-direction-column;
    justify-content: $flex-start;
}

.testataMobile {
    display: $d-flex;
    justify-content: $flex-content-center;
}

.testataMobile .logo-mobile {
    max-width: 200px;
}

.testataMobile a::after {
    display: none;
}

.elencoVociMenuMobile nav {
    display: $d-flex;
    flex-direction: $flex-direction-column;
}

.elencoVociMenuMobile nav ul {
    margin: $p-2 0;
}

.elencoVociMenuMobile nav ul li {
    flex-basis: 100%;
    justify-content: $flex-start;
    align-items: $flex-start;
    display: $d-flex;
    flex-direction: $flex-direction-column;
    padding: 0;
    margin: $p-1 0;
    position: relative;
}

.elencoVociMenuMobile nav ul li::after {
    position: absolute;
    right: calc(5%);
    transition: $trans200;
}

.elencoVociMenuMobile nav ul li a,
.menu-row,
.menu-sub-row {
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: $p-1 0;
}

.elencoVociMenuMobile nav ul li.selected::after {
    @include transform(rotate(90deg));
}

.nav-header-bottom ul.subnavMobile {
    display: none !important;
    font-family: $font-regular;
    font-size: $font-size-16;
    padding: 0 $p-3;
    margin: $p-1 0 $p-3 0;
    transition: $trans200;
}

.nav-header-bottom ul.subnavMobile.selected {
    display: $d-flex !important;
}

.elencoVociMenuMobile nav ul li.spinnerMobile::after {
    display: none !important;
}

.menuCategorieMobile {
    background-color: $azzurro_chiaro_cliente;
    padding: $p-2 $p-3 !important;
    border-radius: 10px;
    margin-top: 0 !important;
    font-size: $font-size-14;
}

.titoloCatalogo {
    margin-bottom: 0 !important;
    margin-top: $p-2 !important;
    color: $verde_cliente;
}

.titoloCatalogo::after {
    display: none !important;
}

.menu-sub-row {
    cursor: pointer;
}

.nav-header-bottom ul.subSubNavMobile {
    padding: 0 $p-3;
}

.swal2-container.menu-mobile.swal2-grow-fullscreen > .swal2-modal {
    justify-content: $flex-start;
}

.swal2-container.menu-mobile .swal2-content {
    padding: 0;
}

.elencoVociMenuMobile ul {
    margin: 0;
    padding: 0;
}

.elencoVociMenuMobile a {
    position: relative;
    transition: $trans200;
    text-decoration: none;
    color: $blu_cliente;
    padding-bottom: $p-1;
}

.elencoVociMenuMobile a::after {
    color: $blu_cliente;
    @include aniLink($blu_cliente, 3px);
}

.elencoVociMenuMobile a:hover {
    color: $blu_cliente;
}

.elencoVociMenuMobile a:hover::after {
    color: $blu_cliente;
    @include aniLinkHover($blu_cliente, 3px);
}
