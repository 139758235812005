// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_'),
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + '.' + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
    src: $src;
  }
}

// =============================================================================
// Animazione link hover
// =============================================================================
@mixin aniLink($color, $height) {
  content: '';
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  height: $height;
  bottom: 0;
  left: 0;
  background-color: $color;
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1),
    -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  border: 0;
}

@mixin aniLinkHover($color, $height) {
  @include aniLink($color, $height);
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

// =============================================================================
// Varie
// =============================================================================
@mixin transition($time) {
  transition: $time;
  -webkit-transition: $time;
  -moz-transition: $time;
  -o-transition: $time;
}

@mixin transform($transformation) {
  transform: $transformation;
  -webkit-transform: $transformation;
  -moz-transform: $transformation;
  -ms-transform: $transformation;
  -o-transform: $transformation;
}

@mixin filter($filter) {
  filter: $filter;
  -webkit-filter: $filter;
  -moz-filter: $filter;
  -ms-filter: $filter;
  -o-filter: $filter;
}

@mixin color-divs($count, $baseName, $startcolor) {
  $loop_color: $startcolor;

  @for $i from 0 through $count {
    $loop_color: darken($loop_color, 9%);

    .#{$baseName}-#{$i} {
      background-color: $loop_color;
    }
  }
}

@function random_element($elements...) {
  @return nth($elements, random(length($elements)));
}
