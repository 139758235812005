header {
    display: $d-flex;
    flex-direction: $flex-direction-column;
    align-items: $flex-content-center;
}

html:not(.rtl) .testataiconelogo {
    width: 100%;
    @include media-breakpoint-up(uhd) {
        max-width: 1920px;
        left: unset;
    }
    @include media-breakpoint-down(xl) {
        left: unset;
        max-width: 100%;
    }
}

html.rtl .testataiconelogo {
    width: 100%;
    @include media-breakpoint-up(uhd) {
        max-width: 1920px;
        right: unset;
    }
    @include media-breakpoint-down(xl) {
        padding-left: unset;
        right: unset;
        max-width: 100%;
    }
}

.headerTop {
    height: 124px;
    @include media-breakpoint-down(lg) {
        height: auto;
    }
}

.header-middle {
    width: 100%;
    max-width: 2000px;
}

.logo-header {
    padding: $p-0;
}

#payoff_mobile {
    flex-direction: $flex-direction-column;
}

#payoff_mobile span {
    color: $white;
    line-height: $line-height-sm;
}

.iconeboxheader {
    a,
    span {
        @include media-breakpoint-down(sm) {
            color: $bianco !important;
        }
    }
    @include media-breakpoint-down(sm) {
        background-color: $verde_cliente;
    }
}

@media (max-width: 450px) {
    #payoff_mobile span.descrizione {
        font-size: $font-size-13;
    }
    .logo-header img {
        max-width: 100%;
    }
}

@media (max-width: 400px) {
    #payoff_mobile span.descrizione {
        font-size: $font-size-12;
        padding-bottom: 0;
    }
}

@media (max-width: 350px) {
    #payoff_mobile span.descrizione {
        font-size: $font-size-10;
    }
}

.header-payoff {
    position: absolute;
    top: 20%;
    background-color: rgba(255, 255, 255, 0.7);
    color: $verde_cliente;
}

.header-payoff .col-12 {
    max-width: 580px;
}

.payoffHeader {
    top: calc(50% - 170px);
    transform: translateY(-50%) translateY(85px);
    @include media-breakpoint-down(md) {
        transform: translateY(0px);
    }
}

.immagine-header.homepage img {
    width: 28vh;
}
.menu-header {
    color: $grigio_chiaro_cliente;
    align-self: $flex-content-center;
    z-index: 2;
    padding: $p-2;
    width: 100%;
}

.menu-header ul {
    list-style: none;
    display: $d-flex;
    justify-content: $flex-space-around;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(md) {
        font-size: $font-size-10;
    }

    @include media-breakpoint-up(lg) {
        font-size: $font-size-11;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size-14;
    }
}

.menu-header ul li {
    float: left;

    @include media-breakpoint-up(md) {
        padding: 0 $p-1;
    }

    @include media-breakpoint-up(xl) {
        padding: 0;
    }
}

.menu-header ul li:hover {
    color: $verde_cliente;
}

.menu-header ul li > ul li {
    float: unset;
    padding: $p-1;
}

.ricerca-veloce-top {
    width: 150px;
    border-radius: 25px;
    border: 1px solid $grigio_chiaro_cliente;
}
.ricerca-veloce-top:focus-visible {
    outline: none;
}

.headerBottom .swiper-slide .productName {
    position: relative;
    padding-bottom: $p-1;
    transition: $trans200;
}
.headerBottom .swiper-slide .productName::after {
    @include aniLink($verde_cliente, 3px);
}
.headerBottom .swiper-slide:hover .productName::after {
    @include aniLinkHover($verde_cliente, 3px);
}
