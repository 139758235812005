// =============================================================================
// Questi sono le variabili custom del sito, $colorecustom: #hexcolore;
// =============================================================================
$verde_cliente: #104124;
$verdechiaro_cliente: #8dd036;
$verdemedio_cliente: #00862d;
$verdeacqua_cliente: #64b182;
$verdergba_cliente: rgba(0, 134, 45, 0.67);
$arancione_cliente: #ea4a00;
$grigio_chiaro_cliente: #cbcbcb;
$grigio_chiaro2_cliente: #d1d1d1;
$grigio_chiaro3_cliente: rgba(246, 246, 246, 1);
$grigio_chiaro3_cliente_opaco: rgba(93, 92, 92, 0.75);
$grigio_chiaro4_cliente: #d3d8df;
$grigio_scuro_cliente: #707070;
$grigio_chiaro5_cliente: #dddddd;
$grigio_azzurro_cliente: #d7e1db;
$grigio_verde_cliente: #c1d0c7;
$azzurro_chiaro_cliente: #e5f3f5;
$giallo_cliente: #ffbb00;
$giallo_cliente2: #fcdc2f;
$giallo_cliente_opaco: rgba(255, 234, 54, 0.5);
$giallochiaro_cliente: #fff67f;
$bianco: #ffffff;
$nero: #000000;
$blu_cliente: #004077;
