$larghezza_menu: 40;
$larghezza_menuleft: 70;
$altezza_menu: 30;
$altezza_menu_mobile: 22;
$altezza_linee_menu: 5;
$altezza_linee_menu_mobile: 3;

body.menuopen {
    pointer-events: none;
    position: fixed;
}

body:not(.menuopen) .chiudimenu.d-block {
    display: none !important;
}

body.menuopen .cc-window {
    pointer-events: auto !important;
}

.menuleft {
    height: 100vh;
    width: #{$larghezza_menuleft}px;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    pointer-events: auto !important;
    @include media-breakpoint-down(xl) {
        height: 0px;
        width: 100%;
    }
}

.menuleft.open {
    width: 100%;
    overflow-y: auto;
    @include media-breakpoint-down(xl) {
        height: 100vh;
    }
}
.menuleft {
    min-width: #{$larghezza_menuleft}px;
    @include media-breakpoint-down(xl) {
        min-width: 100%;
    }
}

.menuleft .leftnav:not(.open) {
    display: none;
}
.menuleft .leftnav {
    width: calc(100% - #{$larghezza_menuleft}px);
    @include media-breakpoint-down(xl) {
        width: 100%;
    }
}
.menuleft .leftnav hr {
    height: 2px;
}
.menuleft .leftnav ul {
    list-style: none;
    padding-left: 0px;
}
.menuleft .leftnav ul li {
    padding: 0.5rem 0;
}
.menuleft .leftnav ul.leftul2 li {
    padding: 0.2rem 0;
}

/* Icon 1 */
#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
    width: #{$larghezza_menu}px;
    height: #{$altezza_menu}px;
    position: relative;
    margin: 5px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    @include media-breakpoint-down(xl) {
        height: #{$altezza_menu_mobile}px;
    }
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
    display: block;
    position: absolute;
    height: #{$altezza_linee_menu}px;
    width: 100%;
    border-radius: #{$altezza_linee_menu}px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    @include media-breakpoint-down(xl) {
        height: #{$altezza_linee_menu_mobile}px;
        border-radius: #{$altezza_linee_menu_mobile}px;
    }
}

#nav-icon1 span:nth-child(1) {
    top: 0px;
}

#nav-icon1 span:nth-child(2) {
    top: #{$altezza_linee_menu * 2}px;
    @include media-breakpoint-down(xl) {
        top: #{$altezza_linee_menu_mobile * 3}px;
    }
}

#nav-icon1 span:nth-child(3) {
    top: #{$altezza_linee_menu * 4}px;
    @include media-breakpoint-down(xl) {
        top: #{$altezza_linee_menu_mobile * 6}px;
    }
}

#nav-icon1.open span:nth-child(1) {
    top: #{$altezza_linee_menu * 2}px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    @include media-breakpoint-down(xl) {
        top: #{$altezza_linee_menu_mobile * 3}px;
    }
}

#nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -#{$larghezza_menu}px;
}

#nav-icon1.open span:nth-child(3) {
    top: #{$altezza_linee_menu * 2}px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    @include media-breakpoint-down(xl) {
        top: #{$altezza_linee_menu_mobile * 3}px;
    }
}

.menuleft.open .nav-icon-span {
    color: #5d5c5c !important;
}
#nav-icon1.open .bkg_bianco {
    background-color: #5d5c5c !important;
}

/* Icon 2 */

#nav-icon2 span {
    display: block;
    position: absolute;
    height: #{$altezza_linee_menu}px;
    width: 50%;
    background: #d3531a;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    @include media-breakpoint-down(xl) {
        height: #{$altezza_linee_menu_mobile}px;
    }
}

#nav-icon2 span:nth-child(even) {
    left: 50%;
    border-radius: 0 #{$altezza_linee_menu * 2}px #{$altezza_linee_menu * 2}px 0;
    @include media-breakpoint-down(xl) {
        border-radius: 0 #{$altezza_linee_menu_mobile * 3}px #{$altezza_linee_menu_mobile *
            3}px 0;
    }
}

#nav-icon2 span:nth-child(odd) {
    left: 0px;
    border-radius: #{$altezza_linee_menu * 2}px 0 0 #{$altezza_linee_menu * 2}px;
    @include media-breakpoint-down(xl) {
        border-radius: #{$altezza_linee_menu_mobile * 3}px 0 0 #{$altezza_linee_menu_mobile *
            3}px;
    }
}

#nav-icon2 span:nth-child(1),
#nav-icon2 span:nth-child(2) {
    top: 0px;
}

#nav-icon2 span:nth-child(3),
#nav-icon2 span:nth-child(4) {
    top: #{$altezza_linee_menu * 2}px;
    @include media-breakpoint-down(xl) {
        top: #{$altezza_linee_menu_mobile * 3}px;
    }
}

#nav-icon2 span:nth-child(5),
#nav-icon2 span:nth-child(6) {
    top: #{$altezza_linee_menu * 4}px;
    @include media-breakpoint-down(xl) {
        top: #{$altezza_linee_menu_mobile * 6}px;
    }
}

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
    left: 5px;
    top: 7px;
}

#nav-icon2.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
}

#nav-icon2.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
    left: 5px;
    top: 29px;
}

#nav-icon2.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 29px;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
    top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
    top: 18px;
}

#nav-icon3 span:nth-child(4) {
    top: 36px;
}

#nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

/* Icon 4 */

#nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
    top: 18px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
    top: 36px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
}

#nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 39px;
    left: 8px;
}

.leftul3 li {
    color: $verdechiaro_cliente;
    border: 2px solid transparent;
}

html:not(.rtl) .leftul3 li > .linkcategory {
    padding-left: 10rem !important;
    @include media-breakpoint-down(xl) {
        padding-left: 5rem !important;
    }
}
html.rtl .leftul3 li > .linkcategory {
    padding-right: 10rem !important;
    @include media-breakpoint-down(xl) {
        padding-right: 5rem !important;
    }
}

.leftul3 li .series {
    color: $verdechiaro_cliente;
    border: 2px solid $verdechiaro_cliente;
}
.leftul3 li .speciali {
    color: $verdechiaro_cliente;
    border: 2px solid $verdechiaro_cliente;
}
.leftul3 li .prospace {
    color: $giallo_cliente;
    border: 2px solid $giallo_cliente;
}
.leftul3 li .proelect {
    color: $giallo_cliente;
    border: 2px solid $giallo_cliente;
}
.leftul3 li img:not(.icon-rettangolo_girato) {
    @include media-breakpoint-down(xl) {
        left: clamp(-20px, -7vw, -7vw) !important;
    }
}

.flagmenu {
    width: #{$larghezza_menuleft}px;
}
html:not(.rtl) .flagmenu::before {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    border-color: transparent $grigio_chiaro2_cliente;
    border-image: none;
    border-style: solid;
    border-width: 0 #{$larghezza_menuleft}px 20px 0;
    bottom: -20px;
    z-index: 2;
    left: 0;
}

html.rtl .flagmenu::before {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    border-color: transparent $grigio_chiaro2_cliente;
    border-image: none;
    border-style: solid;
    border-width: 0 #{$larghezza_menuleft}px 20px 0;
    bottom: -20px;
    z-index: 2;
    right: 0;
}

.flagmenu ul {
    padding: 0;
    list-style: none;
}
.flagmenu ul li {
    padding: 0.75rem 1rem;
    @include media-breakpoint-down(lg) {
        padding: 0.5rem 1.2rem;
    }
}
.flagmenu ul li > img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
